import { logout } from '~auth'
import { queryCache } from '~utils/query-client'

export interface ClientConfig extends Omit<RequestInit, 'body'> {
  data?: any
  token?: string
}

const apiUrl = '/api'

export const parseJson = async <T = any>(
  res: Response,
): Promise<T | string> => {
  const text = await res.text()

  try {
    return JSON.parse(text) as T
  } catch {
    return text
  }
}

export const client = async <T>(
  endpoint: string,
  { data, headers, token, ...customConfig }: ClientConfig = {},
): Promise<T> => {
  const isFormData = data instanceof FormData
  const body = data ? (isFormData ? data : JSON.stringify(data)) : undefined

  const config: RequestInit = {
    method: data ? 'POST' : 'GET',
    credentials: 'include',
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
      ...headers,
    },
    body,
    ...customConfig,
  }

  return fetch(`${apiUrl}/${endpoint}`, config).then(async response => {
    if (response.status === 401) {
      queryCache.clear()
      logout()
      window.location.assign(window.location.href)
      throw new Error('Not authenticated')
    }

    const data = await parseJson<T>(response)

    return response.ok ? (data as T) : Promise.reject(data)
  })
}
