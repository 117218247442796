import React from 'react'

import { reactPlugin } from '~app-insights'
import { ClientLogger } from '~utils/logger/client-logger'
import { TelemetryLogger } from '~utils/logger/types'

interface Props {
  logger?: TelemetryLogger
}

const Context = React.createContext<TelemetryLogger | undefined>(undefined)

export const TelemetryProvider: React.FC<Props> = ({ children, logger }) => {
  const value = React.useMemo(
    () => logger ?? new ClientLogger(reactPlugin),
    [logger],
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useTelemetry = (): TelemetryLogger => {
  const state = React.useContext(Context)

  if (!state) {
    throw new Error('useAppInsights must be used within a AppInsightsProvider')
  }

  return state
}
