import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React from 'react'

import { defaultTheme } from '~styles'

export const ThemeProvider: React.FC = ({ children }) => {
  return (
    <EmotionThemeProvider theme={defaultTheme}>{children}</EmotionThemeProvider>
  )
}
