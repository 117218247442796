import React from 'react'

import { styled } from '~styles'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  error: Error
}

const Root = styled.div`
  color: ${({ theme }) => theme.danger};
`

const ErrorMessage: React.FC<Props> = ({ error, ...props }) => (
  <Root role="alert" {...props}>
    {error.message}
  </Root>
)

export default ErrorMessage
