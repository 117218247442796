import { z } from 'zod'

export const ClientConfig = z.object({
  appInsightsKey: z.string().optional(),
  contactEmail: z.string(),
  contactPhone: z.string(),
  features: z.object({
    phoneVerification: z.boolean(),
  }),
  formMode: z.number().int(),
  siteTheme: z.string().optional(),
})

export type TClientConfig = z.infer<typeof ClientConfig>

let config: TClientConfig

export function loadConfig() {
  if (config) return config
  config = ClientConfig.parse(window.ENV)
  return config
}
