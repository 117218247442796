import { em } from 'polished'
import React from 'react'
import { FiActivity, FiAlertTriangle, FiCheck } from 'react-icons/fi'

import { WithCustomTheme, css, styled, variantColors } from '~styles'
import { center } from '~styles/mixins'
import { Variant } from '.'

interface Props {
  variant: Variant
}

const variant = ({
  variant,
  theme,
}: WithCustomTheme<{ variant: Variant }>) => css`
  background-color: ${theme[variantColors[variant]]};
`

const Root = styled.div`
  ${variant}
  border-radius: ${({ theme }) => theme.borderRadiusRound};
  color: ${({ theme }) => theme.white};
  font-size: 1rem;
  height: ${em(20)};
  position: relative;
  width: ${em(20)};
`

const icon = css`
  ${center('1em')}
  color: inherit;
  font-size: ${em(14)};
`

const renderIcon = (variant: Variant) => {
  switch (variant) {
    case 'info':
      return <FiActivity css={icon} />
    case 'success':
      return <FiCheck css={icon} />
    case 'warning':
      return <FiAlertTriangle css={icon} />
    case 'danger':
      return <FiAlertTriangle css={icon} />
  }
}

const Icon: React.FC<Props> = ({ variant, ...props }) => (
  <Root aria-hidden="true" variant={variant} {...props}>
    {renderIcon(variant)}
  </Root>
)

export default Icon
