import { useMutation } from 'react-query'

import { useAuth } from '~context/auth-context'
import { client } from '~utils/api-client'

export const platformEventMap = {
  application_started: 9,
  application_submitted: 8,
  login_success: 11,
} as const

export type PlatformEvent = keyof typeof platformEventMap

export async function sendPlatformEvent({
  eventType,
  leadId,
  subscriber,
}: {
  eventType: PlatformEvent
  leadId: string
  subscriber: string
}): Promise<void> {
  return client(`subscribers/${subscriber}/platform-events`, {
    data: {
      eventType: platformEventMap[eventType],
      leadId,
    },
  })
}

export function useSendPlatformEvent() {
  const { claims, subscriber } = useAuth()
  if (!claims || !subscriber) {
    throw new Error('Not Authenticated')
  }
  return useMutation((eventType: PlatformEvent) =>
    sendPlatformEvent({ eventType, leadId: claims.LeadId, subscriber }),
  )
}
