import React from 'react'

import { AuthProvider } from './auth-context'
import { SnackbarProvider } from './snackbar-context'
import { ThemeProvider } from './theme-context'

export const AppProviders: React.FC = ({ children }) => (
  <ThemeProvider>
    <SnackbarProvider>
      <AuthProvider>{children}</AuthProvider>
    </SnackbarProvider>
  </ThemeProvider>
)
