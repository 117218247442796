import { Subscriber } from '~models/common'

export const subscriberNames = {
  [Subscriber.BC]: 'BC',
  [Subscriber.DT]: 'DT',
} as const

type SubscriberNameMap = typeof subscriberNames

export type SubscriberName = SubscriberNameMap[Subscriber]
