import { AssetType, AccountType, Custodian, OrderType } from '~models/common'
import { TransactionStatus } from '~models/trade'
import { SubscriberName } from './subscriber'

export const accountTypeLabels: Record<AccountType, string> = {
  [AccountType.TraditionalIra]: 'Traditional IRA',
  [AccountType.RothIra]: 'Roth IRA',
  [AccountType.SepIra]: 'Sep IRA',
  [AccountType.SimpleIra]: 'Simple IRA',
  [AccountType.InheritedIra]: 'Inherited IRA',
  [AccountType.Regular401K]: 'Regular 401K',
  [AccountType.Solo401K]: 'Solo 401K',
  [AccountType.OtherDefinedPlan]: 'Other Defined Plan',
  [AccountType.Simple401K]: 'Simple 401K',
  [AccountType.RolloverIra]: 'Rollover IRA',
  [AccountType.SelfDirectedSepIra]: 'Self Directed Sep IRA',
  [AccountType.Custodial]: 'Custodial',
  [AccountType.BeneficiaryIra]: 'Beneficiary IRA',
  [AccountType.Cash]: 'Cash',
  [AccountType.Other]: 'Other',
  [AccountType.Trust]: 'Trust',
  [AccountType.BeneficiaryRothIra]: 'Beneficiary Roth IRA',
} as const

export const assetTypeLabels: Record<AssetType, string> = {
  [AssetType.BTC]: 'Bitcoin',
  [AssetType.BCH]: 'Bitcoin Cash',
  [AssetType.LTC]: 'Litecoin',
  [AssetType.ETH]: 'Ethereum',
  [AssetType.Cash]: 'Cash',
  [AssetType.XRP]: 'Ripple',
  [AssetType.ETC]: 'Ethereum Classic',
  [AssetType.BTG]: 'Bitcoin Gold',
  [AssetType.XLM]: 'Stellar Lumens',
  [AssetType.ZEC]: 'Zcash',
  [AssetType.PAXG]: 'Gold',
  [AssetType.BCSV]: 'Bitcoin Cash SV',
  [AssetType.DG]: 'Digital Gold',
} as const

export const custodianLabels: Record<Custodian, string> = {
  [Custodian.KingdomTrust]: 'Kingdom Trust',
  [Custodian.BitGo]: 'BitGo',
  [Custodian.DigitalTrust]: 'Digital Trust',
} as const

export const orderTypeLabels: Record<OrderType, string> = {
  [OrderType.Buy]: 'Purchase',
  [OrderType.Sell]: 'Sale',
  [OrderType.Swap]: 'Transfer',
} as const

export const swapOrderTypeLabels: Record<OrderType, string> = {
  [OrderType.Buy]: 'Transfer (Purchase)',
  [OrderType.Sell]: 'Transfer (Sale)',
  [OrderType.Swap]: 'Transfer',
} as const

export const subscriberLabels: Record<SubscriberName, string> = {
  BC: 'Bitcoin IRA',
  DT: 'Digital Trust',
} as const

export const transactionStatusLabels: Record<TransactionStatus, string> = {
  [TransactionStatus.Settled]: 'Settled',
  [TransactionStatus.Deleted]: 'Deleted',
}
