import { keyframes } from '@emotion/react'
import { em } from 'polished'
import React from 'react'

import { styled } from '~styles'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  size?: number
}

const ease = keyframes`
	50% {
		opacity: 1;
		transform: scale(1);
	}
  100% {
		opacity: 0;
	}
`

const Root = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.grayDarker};
  display: flex;
  flex: 1 0;
  justify-content: center;
`

const Icon = styled.div`
  animation: ${ease} 900ms ease infinite;
  background-color: currentColor;
  border-radius: 9999px;
  display: inline-block;
  height: 1em;
  transform: scale(0);
  width: 1em;

  &:not(:last-child) {
    margin-right: ${em(8)};
  }

  &:nth-of-type(2) {
    animation-delay: 300ms;
  }

  &:nth-of-type(3) {
    animation-delay: 600ms;
  }
`

const Loading: React.FC<Props> = ({ size = 20, ...props }) => (
  <Root aria-label="loading" css={{ fontSize: size }} {...props}>
    <Icon />
    <Icon />
    <Icon />
  </Root>
)

export default Loading
