const typography = {
  type: {
    sansSerif: [
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
    axiforma: () => ['Axiforma', ...typography.type.sansSerif],
    monospace: ['monospace'] as const,
  },
} as const

export { typography }
