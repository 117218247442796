export enum AssetType {
  BTC = 1,
  BCH = 2,
  LTC = 3,
  ETH = 4,
  Cash = 5,
  XRP = 6,
  ETC = 7,
  BTG = 8,
  XLM = 9,
  ZEC = 10,
  PAXG = 12,
  BCSV = 13,
  DG = 14,
}
