/* eslint-disable import/no-anonymous-default-export */
export type TrackerEvent =
  | { type: 'identify'; leadId: string }
  | { type: 'account_created'; leadId: string }
  | {
      type: 'application_submitted'
      leadId: string
    }

export interface Tracker {
  send(event: TrackerEvent): void
}

const accountCreatedId = 34772
const applicationSubmittedId = 36558

function sendImpact(...args: any[]) {
  if (typeof window !== 'undefined') {
    window.ire?.(...args)
  }
}

export const impactTracker: Tracker = {
  send(event) {
    switch (event.type) {
      case 'identify':
        sendImpact('identify', {
          customerId: event.leadId,
          customerEmail: '',
        })
        break
      case 'account_created':
        sendImpact('trackConversion', accountCreatedId, {
          customerId: event.leadId,
          customerEmail: '',
          orderId: event.leadId,
        })
        break
      case 'application_submitted':
        sendImpact('trackConversion', applicationSubmittedId, {
          customerId: event.leadId,
          customerEmail: '',
          orderId: event.leadId,
        })
        break
    }
  },
}
