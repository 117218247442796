/* eslint-disable @typescript-eslint/no-empty-function */
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { createBrowserHistory } from 'history'

import { loadConfig } from '~config.client'

export const reactPlugin = new ReactPlugin()

const history = createBrowserHistory()

function createNullAppInsights(): ApplicationInsights {
  return {
    appInsights: {
      trackEvent: () => {},
      trackPageView: () => {},
      trackException: () => {},
      trackTrace: () => {},
      trackMetric: () => {},
    },
    loadAppInsights: () => {},
  } as any
}

function createAppInsights(instrumentationKey?: string) {
  if (!instrumentationKey) return createNullAppInsights()

  return new ApplicationInsights({
    config: {
      instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history },
      },
      disableFetchTracking: false,
      enableAjaxErrorStatusText: true,
      enableUnhandledPromiseRejectionTracking: true,
    },
  })
}

const { appInsightsKey } = loadConfig()
const ai = createAppInsights(appInsightsKey)
ai.loadAppInsights()

export const appInsights = ai.appInsights
