import { OneTimeTokenClaims, UserClaims } from '@digital-trust/common'

import {
  LoginRequest,
  LoginResponse,
  NotifyPasswordResetRequest,
  PasswordResetRequest,
  RegisterRequest,
  RegisterResponse,
  VerifyOneTimeTokenResponse,
  VerifyResponse,
} from '~models/auth'
import { parseJson } from '~utils/api-client'
import { Result } from '~utils/result'

type InitiateResetPasswordVariables = {
  request: NotifyPasswordResetRequest
}

type ResetPasswordVariables = {
  request: PasswordResetRequest
}

interface ClientConfig extends Omit<RequestInit, 'body'> {
  data?: AnyJson
  token?: string
}

export const authKey = 'auth-token'
const authUrl = '/api/v1/authentication'

const client = async <T>(
  endpoint: string,
  { data, headers, token, ...customConfig }: ClientConfig = {},
): Promise<Result<T, Response>> => {
  const config: RequestInit = {
    method: data ? 'POST' : 'GET',
    credentials: 'include',
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      'Content-Type': 'application/json',
      ...headers,
    },
    body: data ? JSON.stringify(data) : undefined,
    ...customConfig,
  }

  const response = await fetch(`${authUrl}/${endpoint}`, config)

  if (response.ok) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedData = await parseJson(response)
    return Result.Ok(parsedData as T)
  }

  return Result.Err(response)
}

export const hasToken = () => document.cookie.includes(authKey)

export const getToken = (): string | undefined => {
  const authCookieValues = document.cookie
    .split('; ')
    .filter(cookie => cookie.includes(authKey))

  return authCookieValues.length > 0
    ? authCookieValues[0].split('=')[1]
    : undefined
}

export const login = async (data: LoginRequest): Promise<UserClaims> => {
  const result = await client<LoginResponse>('login', { data })
  switch (result._tag) {
    case 'Ok':
      return result.ok.claims
    case 'Err':
      if (result.err.status === 401) {
        throw new Error('Invalid username and/or password.')
      }

      throw new Error(await parseJson(result.err))
  }
}

export const loginOneTimePassword = async (
  token: string,
): Promise<OneTimeTokenClaims> => {
  const result = await client<VerifyOneTimeTokenResponse>(
    'one-time-token/verify',
    {
      data: token,
    },
  )

  switch (result._tag) {
    case 'Ok':
      return result.ok.claims
    case 'Err':
      if (result.err.status === 401) {
        throw new Error('Provided login token is not authorized.')
      }

      throw new Error(await parseJson(result.err))
  }
}

export const logout = () => {
  // eslint-disable-next-line unicorn/no-document-cookie
  document.cookie = `${authKey}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export const register = async (data: RegisterRequest): Promise<UserClaims> => {
  const result = await client<RegisterResponse>('register', { data })

  switch (result._tag) {
    case 'Ok':
      return result.ok.claims
    case 'Err':
      throw new Error(await parseJson(result.err))
  }
}

export const verify = async (): Promise<UserClaims> => {
  const headers: Record<string, string> =
    process.env.NODE_ENV === 'test' ? { Cookie: document.cookie } : {}

  const result = await client<VerifyResponse>('verify', {
    headers,
    token: getToken(),
  })

  switch (result._tag) {
    case 'Ok':
      return result.ok.claims
    case 'Err':
      throw new Error(await parseJson(result.err))
  }
}

export const initiateResetPassword = async ({
  request,
}: InitiateResetPasswordVariables): Promise<void> => {
  const result = await client<void>(`password-reset/request`, { data: request })

  switch (result._tag) {
    case 'Ok':
      return result.ok
    case 'Err':
      if (result.err.status === 401) {
        throw new Error('User not found')
      }

      throw new Error(await parseJson(result.err))
  }
}

export const resetPassword = async ({
  request,
}: ResetPasswordVariables): Promise<void> => {
  const result = await client<void>(`password-reset`, { data: request })

  switch (result._tag) {
    case 'Ok':
      return result.ok
    case 'Err':
      if (result.err.status === 400) {
        throw new Error(
          'Invalid password reset request: Provided information does not match user account.',
        )
      }

      throw new Error(await parseJson(result.err))
  }
}
