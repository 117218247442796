import {
  PanInfo,
  motion,
  useAnimation,
  useMotionValue,
  useTransform,
} from 'framer-motion'
import { em, rem, transparentize } from 'polished'
import React from 'react'

import { useTimer } from '~hooks/use-timer'
import { WithCustomTheme, css, styled, variantColors } from '~styles'
import { heading, unselectable } from '~styles/mixins'
import CloseButton from './close-button'
import Icon from './icon'
import BaseTimerProgress from './timer-progress'

export type Variant = 'info' | 'success' | 'warning' | 'danger'

export interface Props {
  autoDismiss?: boolean
  autoDismissTimeout?: number
  children: React.ReactNode
  onDismiss?: () => void
  title?: string
  variant?: Variant
}

const variant = ({
  variant,
  theme,
}: WithCustomTheme<{ variant: Variant }>) => css`
  background-color: ${theme[variantColors[variant]]};
`

const fontSize = 12

const Root = styled(motion.div)`
  ${unselectable}
  background: ${({ theme }) => theme.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 4px 7px 0 ${({ theme }) => transparentize(0.85, theme.black)};
  color: ${({ theme }) => theme.textLightColor};
  display: flex;
  font-size: ${rem(fontSize)};
  overflow: hidden;
  padding: ${rem(10)} ${rem(10)} ${rem(10)} ${rem(15)};
  position: relative;
`

const Bar = styled.div<{ variant: Variant }>`
  ${variant}
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: ${em(5)};
  z-index: 1;
`

const Title = styled.div`
  ${heading(600)}
  font-size: ${em(14, fontSize)};
  margin-bottom: ${em(5, fontSize)};
`

const TimerProgress = styled(BaseTimerProgress)`
  position: absolute;
  left: 0;
  bottom: -5px;
  right: 0;
`

const Snackbar: React.FC<Props> = ({
  autoDismiss = false,
  autoDismissTimeout = 5000,
  children,
  onDismiss,
  title,
  variant = 'info',
  ...props
}) => {
  const isExpired = useTimer(autoDismiss, autoDismissTimeout)

  React.useEffect(() => {
    if (isExpired) {
      onDismiss?.()
    }
  }, [isExpired, onDismiss])

  const controls = useAnimation()
  const x = useMotionValue(0)

  const inputX = [-500, 0, 500]
  const outputOpacity = [0, 1, 0]
  const opacity = useTransform(x, inputX, outputOpacity)

  const handleDragEnd = async (_event: any, info: PanInfo) => {
    const offset = info.offset.x
    const velocity = info.velocity.x

    if (Math.abs(offset) > 150 || Math.abs(velocity) > 500) {
      await controls.start({
        x: offset < 0 ? -500 : 500,
        opacity: 0,
        transition: { duration: 0.2 },
      })
      onDismiss?.()
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    controls.start({ x: 0, opacity: 1, transition: { duration: 0.5 } })
  }

  return (
    <Root
      animate={controls}
      drag="x"
      dragDirectionLock
      onDragEnd={handleDragEnd}
      role="alert"
      style={{ x, opacity }}
      {...props}
    >
      {autoDismiss && <TimerProgress duration={autoDismissTimeout} />}
      <Bar variant={variant} />
      <Icon css={{ marginRight: rem(10) }} variant={variant} />
      <div css={{ flex: 1 }}>
        <Title>{title ?? variant}</Title>
        {children}
      </div>
      <CloseButton onClick={() => onDismiss?.()} />
    </Root>
  )
}

export default Snackbar
