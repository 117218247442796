import React from 'react'
import { motion } from 'framer-motion'

import { styled } from '~styles'

interface Props {
  duration: number
}

const Root = styled.svg`
  height: 10px;
  width: 100%;
`
Root.defaultProps = {
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
}

const Path = styled(motion.path)`
  fill: none;
  stroke: ${({ theme }) => theme.grayLighter};
  stroke-width: 20;
  stroke-opacity: 0.5;
`

const TimerProgress: React.FC<Props> = ({ duration, ...props }) => (
  <Root viewBox="0 0 500 5" {...props}>
    <Path
      d="M 0,5 l 500,0"
      initial={{ pathLength: 1 }}
      animate={{ pathLength: 0 }}
      transition={{
        duration: Math.floor(duration / 1000),
        ease: 'easeOut',
      }}
    />
  </Root>
)

export default TimerProgress
