import crypto from 'crypto'

export const randomId = (len = 8) => {
  if (len < 0) {
    throw new Error('Length must be positive')
  }

  return crypto.randomBytes(Math.floor(len / 2)).toString('hex')
}

export const randomDate = (start = new Date(2020, 0, 1), end = new Date()) =>
  new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))

export const randomFloat = (min = 0, max = 100) =>
  Math.random() * (max - min) + min

export const randomInt = (min = 0, max = 100) =>
  Math.floor(randomFloat(min, max))

// eslint-disable-next-line unicorn/no-new-array,unicorn/no-useless-spread
export const range = (len: number) => [...new Array<unknown>(len)]

export const isNil = (val: any) => val === undefined || val === null

export const isError = (obj: unknown): obj is Error => obj instanceof Error

export const isFunction = (val: any): val is Function =>
  !!(val && Object.prototype.toString.call(val) === '[object Function]')

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {}
