import { MediaBreakpoint } from '@emotion/react'

import { breakpoints } from './theme'

type MediaQueryMap = Record<MediaBreakpoint, string>

export const mediaQueries: MediaQueryMap = Object.entries(breakpoints).reduce(
  (acc, [key, val]) => ({
    ...acc,
    [key]: `(min-width: ${val}px)`,
  }),
  {} as MediaQueryMap,
)

export const getQuery = (breakpoint: MediaBreakpoint): string => {
  if (!Object.keys(breakpoints).includes(breakpoint)) {
    throw new Error('invalid breakpoint provided')
  }

  return `${mediaQueries[breakpoint]}`
}

export const mq = (breakpoint: MediaBreakpoint): string =>
  `@media ${getQuery(breakpoint)}`
