import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

import { TelemetryLogger } from './types'

export class ClientLogger implements TelemetryLogger {
  constructor(private client: ReactPlugin) {}

  logEvent = (name: string, properties?: Record<string, any>): void =>
    this.client.trackEvent({ name }, properties)

  logError = (value: Error | string, severity?: SeverityLevel): void => {
    const exception = typeof value === 'object' ? value : new Error(value)

    return this.client.trackException({ exception, severityLevel: severity })
  }

  logPageView = (name: string, url?: string) =>
    this.client.trackPageView({ name, uri: url })
}
