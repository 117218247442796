export enum AccountType {
  TraditionalIra = 1,
  RothIra = 2,
  SepIra = 3,
  SimpleIra = 4,
  InheritedIra = 5,
  Regular401K = 6,
  Solo401K = 7,
  OtherDefinedPlan = 8,
  Simple401K = 9,
  RolloverIra = 10,
  SelfDirectedSepIra = 11,
  Custodial = 12,
  BeneficiaryIra = 13,
  Cash = 14,
  Other = 15,
  Trust = 16,
  BeneficiaryRothIra = 17,
}
