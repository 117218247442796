import { CustomTheme, css, keyframes } from '@emotion/react'
import { em, rem } from 'polished'

import { mq } from './responsive'

type AnyThemeProps = { theme: CustomTheme }

export const hideMediumUp = css`
  ${mq('md')} {
    display: none;
  }
`

export const showMediumUp = css`
  display: none;

  ${mq('md')} {
    display: block;
  }
`

export const center = (width: string, height?: string) => css`
  left: calc(50% - (${width} / 2));
  position: absolute;
  top: calc(50% - (${height ?? width} / 2));
`

export const centerAll = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const spin = keyframes`
	0% {
		transform: rotate(0deg);
	}
  100% {
		transform: rotate(359deg);
	}
`

export const container = ({ theme }: AnyThemeProps) => css`
  margin: 0 auto;
  max-width: ${1000 + 2 * theme.gap}px;
  padding: 0 ${theme.gap}px;
  position: relative;
`

export const heading =
  (fontWeight = 600) =>
  ({ theme }: AnyThemeProps) =>
    css`
      color: ${theme.grayDarker};
      font-weight: ${fontWeight};
      line-height: 1.2;
    `

export const textNoWrap = css`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const rotate = (deg: number) => css`
  -webkit-transform: rotate(${deg}deg);
  transform: rotate(${deg}deg);
`

export const arrow = (color = '#000') => css`
  border: solid ${color};
  border-width: 0 1px 1px 0;
  content: '';
  display: block;
  pointer-events: none;
  position: relative;
  padding: 3px;
`

export const triangle = (color = '#000', size = 16) => css`
  color: ${color};
  content: '▾';
  font-size: ${rem(size)};
`

export const card = ({ theme }: AnyThemeProps) => css`
  background: ${theme.white};
  border: 1px solid ${theme.borderColor};
  border-radius: ${theme.borderRadiusLarge};
  min-height: 400px;
`

export const control = css`
  appearance: none;
  border: 1px solid transparent;
  box-shadow: none;
  height: ${rem(44)};
  justify-content: flex-start;
  outline: none;

  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
  }
`

export const controlLabel = css`
  display: inline-block;
  font-size: ${em(14)};
  font-weight: 600;
  margin-bottom: 10px;
`

export const fieldHelp = (color = '#000') => css`
  color: ${color};
  font-size: ${em(12)};
`

export const fieldHint = (color = '#000') => css`
  color: ${color};
  font-size: ${em(12)};
  font-style: italic;
`

export const radioGroup = css`
  display: flex;

  > div:not(:last-of-type) {
    margin-right: 25px;
  }
`

export const option = ({ theme }: AnyThemeProps) => css`
  select[multiple] & {
    padding: ${rem(8)} ${rem(16)};
  }

  select[disabled][multiple] &,
  fieldset[disabled] select[multiple] & {
    color: ${theme.textLightColor};
  }
`

export const unselectable = css`
  -webkit-touch-callout: none;
  user-select: none;
`

export const focusRing = css`
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
`

export const spinner =
  (color?: string) =>
  ({ theme }: AnyThemeProps) =>
    css`
      animation: ${spin} 600ms infinite linear;
      border: 2px solid ${color ?? theme.grayLighter};
      border-radius: ${theme.borderRadiusRound};
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 1em;
      position: relative;
      width: 1em;
    `

export const visuallyHidden = css`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
`
