import { em } from 'polished'
import React from 'react'

import { styled } from '~styles'
import { center } from '~styles/mixins'

export type Props = Omit<React.HTMLAttributes<HTMLButtonElement>, 'children'>

const Root = styled.button`
  background-color: transparent;
  border-radius: ${({ theme }) => theme.borderRadiusRound};
  border-width: 0;
  color: ${({ theme }) => theme.grayLighter};
  cursor: pointer;
  font-size: 1rem;
  outline: none;
  padding: 0;
  position: relative;
  height: ${em(24)};
  width: ${em(24)};

  &:hover {
    background-color: ${({ theme }) => theme.whiteBis};
    color: ${({ theme }) => theme.grayDark};
  }
`

const Icon = styled.svg`
  ${center(em(10))}
  height: ${em(12)};
  width: ${em(12)};
`

const Path = styled.path`
  color: inherit;
  fill: transparent;
  stroke: currentColor;
  stroke-linecap: square;
  stroke-width: 3;
`

const CloseButton: React.FC<Props> = props => (
  <Root aria-label="close" {...props}>
    <Icon viewBox="0 0 23 23">
      <Path d="M 3 16.5 L 17 2.5" />
      <Path d="M 3 2.5 L 17 16.346" />
    </Icon>
  </Root>
)

export default CloseButton
