/* eslint-disable no-param-reassign */
import { produce } from 'immer'
import { getLuminance, hsl, hsla } from 'polished'
import {
  ColorContrast,
  CustomColor,
  CustomTheme,
  MediaBreakpoint,
  Variant,
} from '@emotion/react'
import tailwindColors from 'tailwindcss/colors'

import { typography } from './tokens'

export const contrastColor = (color: string) =>
  getLuminance(color) > 0.55 ? hsla(0, 0, 0, 0.7) : hsl(0, 0, 1)

const grayDarker = tailwindColors.neutral[800]
const grayDark = tailwindColors.neutral[700]
const gray = tailwindColors.neutral[500]
const grayLight = tailwindColors.zinc[400]
const grayLighter = tailwindColors.zinc[300]
const grayLightest = tailwindColors.gray[200]

const whiteTer = hsl(0, 0, 0.96)
const whiteBis = hsl(0, 0, 0.98)
const white = hsl(0, 0, 1)

const light = whiteTer
const dark = grayDarker

const orange = hsl(14, 1, 0.53)
const yellow = hsl(48, 1, 0.67)
const green = hsl(132, 0.37, 0.69)
const turquoise = hsl(171, 1, 0.41)
const cyan = hsl(204, 0.86, 0.53)
const blue = hsl(217, 0.71, 0.53)
const purple = hsl(271, 1, 0.71)
const red = tailwindColors.rose[500]

const blueContrast = contrastColor(blue)

const primary = turquoise
const secondary = grayLighter
const tertiary = blue
const info = cyan
const success = green
const warning = yellow
const danger = red

const backgroundColor = hsl(0, 0.2, 0.99)

const textColor = grayDark

const gap = 15

export const breakpoints: Record<MediaBreakpoint, number> = {
  sm: 480,
  md: 768 + 2 * gap,
  lg: 960 + 2 * gap,
  xl: 1152 + 2 * gap,
}

const defaultTheme: CustomTheme = {
  black: hsl(0, 0, 0.04),
  blackBis: hsl(0, 0, 0.07),
  blackTer: hsl(0, 0, 0.14),

  grayDarker,
  grayDark,
  gray,
  grayLight,
  grayLighter,
  grayLightest,

  whiteTer,
  whiteBis,
  white,

  light,
  dark,

  lightContrast: dark,
  darkContrast: light,

  orange,
  yellow,
  green,
  turquoise,
  cyan,
  blue,
  purple,
  red,

  orangeContrast: contrastColor(orange),
  yellowContrast: contrastColor(yellow),
  greenContrast: contrastColor(green),
  turquoiseContrast: contrastColor(turquoise),
  cyanContrast: contrastColor(cyan),
  blueContrast,
  purpleContrast: contrastColor(purple),
  redContrast: contrastColor(red),

  primary,
  secondary,
  tertiary,
  info,
  success,
  warning,
  danger,

  primaryContrast: contrastColor(primary),
  secondaryContrast: contrastColor(secondary),
  tertiaryContrast: contrastColor(tertiary),
  infoContrast: contrastColor(info),
  successContrast: contrastColor(success),
  warningContrast: contrastColor(warning),
  dangerContrast: contrastColor(danger),

  backgroundColor,

  borderColor: grayLighter,
  borderHoverColor: grayLight,
  borderLightColor: grayLightest,
  borderLightHoverColor: grayLight,

  textColor,
  textColorContrast: contrastColor(textColor),
  textLightColor: hsl(0, 0, 0.6),
  textStrongColor: grayDarker,
  textSelectionBackgroundColor: hsl(213, 0.92, 0.85),

  codeColor: red,
  codeBackgroundColor: backgroundColor,

  preColor: textColor,
  preBackgroundColor: backgroundColor,

  linkColor: blue,
  linkColorContrast: blueContrast,
  linkVisitedColor: purple,

  linkHoverColor: grayDarker,
  linkHoverBorderColor: grayLight,

  linkFocusColor: grayDarker,
  linkFocusBorderColor: blue,

  linkActiveColor: grayDarker,
  linkActiveBorderColor: grayDark,

  fontFamilyPrimary: typography.type.sansSerif.join(', '),
  fontFamilySecondary: typography.type.sansSerif.join(', '),
  fontFamilyCode: typography.type.monospace.join(', '),
  textRendering: 'optimizeLegibility',

  gap,
  breakpoints,

  timingFunction: 'ease-out',
  borderRadiusSmall: '2px',
  borderRadius: '4px',
  borderRadiusLarge: '7px',
  borderRadiusRound: '9999px',
  duration: '86ms',
}

export const variantColors: Record<Variant, CustomColor> = {
  danger: 'danger',
  dark: 'dark',
  info: 'info',
  light: 'light',
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  success: 'success',
  warning: 'warning',
}

export const variantColorContrasts: Record<Variant, ColorContrast> = {
  danger: 'dangerContrast',
  dark: 'darkContrast',
  info: 'infoContrast',
  light: 'lightContrast',
  primary: 'primaryContrast',
  secondary: 'secondaryContrast',
  tertiary: 'tertiaryContrast',
  success: 'successContrast',
  warning: 'warningContrast',
}

const digitalTrustTheme = produce(defaultTheme, draft => {
  const palette = {
    blue: '#1548cd',
    blueLight: '#2a59d2',
  }

  draft.blue = palette.blue
  draft.blueContrast = contrastColor(palette.blue)
  draft.primary = 'rgb(var(--primary))'
  draft.primaryContrast = contrastColor(palette.blue)
  draft.linkColor = palette.blueLight
  draft.linkColorContrast = contrastColor(palette.blueLight)
})

export { digitalTrustTheme as defaultTheme }
