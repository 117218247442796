import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { z } from 'zod'

import Snackbar, { Props as SnackbarProps } from '~components/snackbar'
import { css, mq, styled } from '~styles'
import { isError, randomId } from '~utils/functional'

type AlertOptions = Omit<SnackbarProps, 'onDismiss' | 'children'>

interface Alert {
  id: string
  content: React.ReactNode
  options?: AlertOptions
}

interface SnackbarApi {
  addAlert: (
    contentOrError: Error | React.ReactNode,
    options?: AlertOptions,
  ) => void
}

const SnackbarContext = React.createContext<SnackbarApi | undefined>(undefined)
SnackbarContext.displayName = 'SnackbarContext'

const List = styled.ul<{ count?: number }>`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: ${({ theme }) => theme.gap}px;
  transform: translate3d(0, 100%, 0);
  width: 100%;

  ${({ count = 0 }) =>
    count &&
    css`
      transform: translate3d(0, 0, 0);
    `}

  ${mq('md')} {
    max-width: 350px;
  }
`

const ListItem = styled(motion.li)`
  &:not(:first-of-type) {
    margin-top: 10px;
  }
`

const PlatformError = z.object({
  Message: z.string(),
})
type PlatformError = z.infer<typeof PlatformError>

const getErrorMessage = (value: any): React.ReactNode => {
  switch (true) {
    case isError(value):
      return (value as Error).message
    case PlatformError.safeParse(value).success:
      return (value as PlatformError).Message
    case typeof value === 'string':
      return value as string
    default:
      return 'Unknown error'
  }
}

export const SnackbarProvider: React.FC = ({ children, ...props }) => {
  const [alerts, setAlerts] = React.useState<Alert[]>([])

  const addAlert = React.useCallback(
    (contentOrError: Error | React.ReactNode, options?: AlertOptions) => {
      const content = getErrorMessage(contentOrError)

      setAlerts([...alerts, { id: randomId(), content, options }])
    },
    [alerts],
  )

  const removeAlert = (alertId: string) =>
    setAlerts(alerts.filter(({ id }) => id !== alertId))

  React.useEffect(() => {
    if (alerts.length > 5) {
      setAlerts(alerts.slice(1))
    }
  }, [alerts, setAlerts])

  const value = React.useMemo(() => ({ addAlert }), [addAlert])

  return (
    <SnackbarContext.Provider value={value} {...props}>
      {children}
      <List count={alerts.length}>
        <AnimatePresence initial={false}>
          {alerts.map(({ id, content, options }) => (
            <ListItem
              key={id}
              initial={{ opacity: 0, y: 50, scale: 0.3 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
            >
              <Snackbar
                autoDismiss
                children={content}
                onDismiss={() => removeAlert(id)}
                {...options}
              />
            </ListItem>
          ))}
        </AnimatePresence>
      </List>
    </SnackbarContext.Provider>
  )
}

export const useSnackbars = () => {
  const state = React.useContext(SnackbarContext)

  if (!state) {
    throw new Error('useSnackbars must be used within a SnackbarContext')
  }

  return state
}
