import React from 'react'
import { ReactQueryDevtools } from 'react-query/devtools'
import withStyles from 'isomorphic-style-loader/withStyles'

import App from '~components/app'
import { AppProviders } from '~context'
// eslint-disable-next-line import/no-unresolved
import styles from '~styles/app.css'

const Root: React.FC = () => (
  <React.StrictMode>
    <AppProviders>
      <App />
      {__IS_DEV__ ? <ReactQueryDevtools /> : undefined}
    </AppProviders>
  </React.StrictMode>
)

export default withStyles(styles)(Root)
