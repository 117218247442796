import { UserClaims } from '@digital-trust/common/models'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { sendPlatformEvent } from '~api/platform-events'
import * as auth from '~auth'
import ErrorMessage from '~components/error-message'
import Loading from '~components/loading'
import { loadConfig } from '~config.client'
import { SubscriberName, subscriberNames } from '~constants'
import { useAsync } from '~hooks/use-async'
import { impactTracker } from '~impact'
import { LoginRequest, RegisterRequest } from '~models/auth'
import { Subscriber } from '~models/common'
import { centerAll } from '~styles/mixins'
import { queryCache } from '~utils/query-client'

export type LoginOneTimePasswordVariables = {
  token: string
}

export interface NavigationState {
  isLogout: boolean
}

export interface Props {
  value?: UserClaims
}

export interface AuthApi {
  login: (request: LoginRequest) => Promise<void>
  loginOneTimePassword: (params: LoginOneTimePasswordVariables) => Promise<void>
  logout: () => void
  register: (request: RegisterRequest) => Promise<void>
  subscriber?: SubscriberName
  claims?: UserClaims
}

const LoadingOverlay = () => (
  <div css={[{ height: '100vh' }, centerAll]}>
    <Loading />
  </div>
)

const retrieveUserClaims = async (): Promise<UserClaims | undefined> =>
  auth.hasToken() ? await auth.verify() : undefined

const AuthContext = React.createContext<AuthApi | undefined>(undefined)
AuthContext.displayName = 'AuthContext'

export const AuthProvider: React.FC<Props> = ({ value: input, ...props }) => {
  const navigate = useNavigate()
  const {
    data: claims,
    error,
    isError,
    isIdle,
    isLoading,
    execute,
    setData,
  } = useAsync<UserClaims | undefined>()

  React.useEffect(() => {
    if (input) return setData(input)

    execute(retrieveUserClaims())
  }, [input, execute, setData])

  const login = React.useCallback(
    (request: LoginRequest) =>
      auth.login(request).then(claims => {
        execute(retrieveUserClaims())
        void sendPlatformEvent({
          eventType: 'login_success',
          leadId: claims.LeadId,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          subscriber: subscriberNames[claims.Subscriber],
        })
        return
      }),
    [execute],
  )

  const loginOneTimePassword = React.useCallback(
    async ({ token }: LoginOneTimePasswordVariables) => {
      const oneTimeTokenClaims = await auth.loginOneTimePassword(token)
      execute(retrieveUserClaims())

      const destinationUrl = oneTimeTokenClaims.DestinationUrl?.includes('http')
        ? oneTimeTokenClaims.DestinationUrl?.split(window.location.origin)?.[1]
        : oneTimeTokenClaims.DestinationUrl

      navigate({
        pathname: destinationUrl ?? '/activate',
        search: `?token=${token}`,
      })
    },
    [navigate, execute],
  )

  const logout = React.useCallback(() => {
    queryCache.clear()
    auth.logout()
    setData(undefined)
    return navigate('/', { state: { isLogout: true } as NavigationState })
  }, [navigate, setData])

  const register = React.useCallback(
    (request: RegisterRequest) =>
      auth
        .register({
          ...request,
          formModeId: loadConfig().formMode,
        })
        .then(claims => {
          execute(retrieveUserClaims())
          impactTracker.send({
            leadId: claims.LeadId,
            type: 'account_created',
          })

          return navigate('/')
        }),
    [navigate, execute],
  )

  const value = React.useMemo(
    () => ({
      login,
      loginOneTimePassword,
      logout,
      register,
      subscriber: claims
        ? subscriberNames[Number.parseInt(claims.Subscriber, 10) as Subscriber]
        : undefined,
      claims,
    }),
    [login, loginOneTimePassword, logout, register, claims],
  )

  if (isIdle || isLoading) {
    return <LoadingOverlay />
  }

  if (isError) {
    logout()
    return (
      <ErrorMessage
        css={[{ height: '100vh' }, centerAll]}
        error={new Error(error)}
      />
    )
  }

  return <AuthContext.Provider value={value} {...props} />
}

export const useAuth = () => {
  const context = React.useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}
