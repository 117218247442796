import { loadableReady } from '@loadable/component'
import { Styles } from 'isomorphic-style-loader'
import StyleContext from 'isomorphic-style-loader/StyleContext'
import { QueryClientProvider } from 'react-query'
import { Renderer, hydrate, render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import Root from '~components/root'
import { TelemetryProvider } from '~context/telemetry-context'
import { queryClient } from '~utils/query-client'

const insertCss = (...styles: Styles[]) => {
  const removeCss = styles.map(style => style._insertCss())
  return () => removeCss.forEach(dispose => dispose())
}

const renderApp = (renderOrHydrate: Renderer) =>
  renderOrHydrate(
    <StyleContext.Provider value={{ insertCss }}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <TelemetryProvider>
            <Root />
          </TelemetryProvider>
        </Router>
      </QueryClientProvider>
    </StyleContext.Provider>,
    document.querySelector('#root'),
  )

// eslint-disable-next-line @typescript-eslint/no-floating-promises
loadableReady(() => renderApp(hydrate))

if (module.hot) {
  module.hot.accept('./components/root', () => renderApp(render))
}
