import React from 'react'

type AsyncStatus = 'idle' | 'pending' | 'success' | 'failure'

type AsyncState<T> = {
  status: AsyncStatus
  error?: string
  data?: T
}

type AsyncStateReducer<T> = React.Reducer<AsyncState<T>, AsyncState<T>>

interface AsyncInstance<T> {
  data?: T
  error?: string
  isError: boolean
  isIdle: boolean
  isLoading: boolean
  isSuccess: boolean
  execute: (promise: Promise<T>) => void
  setData: (data: T) => void
  setError: (error: string) => void
}

const useSafeDispatch = (dispatch: (...args: any[]) => void) => {
  const mounted = React.useRef(false)

  React.useEffect(() => {
    mounted.current = true
  }, [])

  return React.useCallback(
    (...args) => (mounted.current ? dispatch(...args) : undefined),
    [dispatch],
  )
}

const defaultInitialState: AsyncState<any> = { status: 'idle' }

export const useAsync = <T = any>(
  initialState?: AsyncState<T>,
): AsyncInstance<T> => {
  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState,
  })

  const [{ status, data, error }, dispatch] = React.useReducer<
    AsyncStateReducer<T>
  >((state, next) => ({ ...state, ...next }), initialStateRef.current)

  const safeDispatch = useSafeDispatch(dispatch)

  const setData = React.useCallback(
    (data: T) => safeDispatch({ data, status: 'success' }),
    [safeDispatch],
  )

  const setError = React.useCallback(
    (error: string) => safeDispatch({ error, status: 'failure' }),
    [safeDispatch],
  )

  const execute = React.useCallback(
    (promise: Promise<T>) => {
      if (!promise.then) {
        throw new Error(
          `The argument passed to useAsync().run must be a promise`,
        )
      }

      safeDispatch({ status: 'pending' })

      return promise.then(
        data => {
          setData(data)
          return data
        },
        (error: Error | string) => {
          const message =
            typeof error === 'object'
              ? error?.message ?? 'An error occurred'
              : error

          setError(message)
        },
      )
    },
    [safeDispatch, setData, setError],
  )

  return {
    data,
    error,
    isError: status === 'failure',
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isSuccess: status === 'success',
    execute,
    setData,
    setError,
  }
}
