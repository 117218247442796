import loadable from '@loadable/component'
import React from 'react'

import Loading from '~components/loading'
import { useAuth } from '~context/auth-context'
import { centerAll } from '~styles/mixins'

const LoadingOverlay = () => (
  <div css={[{ height: '100vh' }, centerAll]}>
    <Loading />
  </div>
)

const AuthenticatedApp = loadable(
  () =>
    import(
      /* webpackChunkName: "authenticated-app" */
      /* webpackPrefetch: true */
      './authenticated-app'
    ),
)

const UnauthenticatedApp = loadable(
  () =>
    import(
      /* webpackChunkName: "unauthenticated-app" */
      './unauthenticated-app'
    ),
)

const App: React.FC = () => {
  const { claims } = useAuth()

  return claims ? (
    <AuthenticatedApp fallback={<LoadingOverlay />} />
  ) : (
    <UnauthenticatedApp fallback={<LoadingOverlay />} />
  )
}

export default App
