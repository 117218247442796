export enum TradeStatus {
  Submitted = 1,
  Failed = 2,
  Rejected = 3,
  PendingSettlement = 4,
  Settled = 5,
  PendingCancelled = 6,
  Cancelled = 7,
  Deleted = 8,
}
